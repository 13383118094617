import { createMuiTheme } from '@material-ui/core';

const DEFAULT_FONT = 'Poppins';

export const theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '0.95rem',
      },
    },
  },
  typography: {
    fontFamily: [
      DEFAULT_FONT,
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#324177',
    },
  },
});

import { ClientType } from '../types/Client';
import { httpRequest } from './axios';

export interface ResetPassword {
  password: string;
  confirmPassword: string;
}

export const checkResetPassword = async (token: string, type: ClientType) => {
  if (type !== 'CLIENT') return httpRequest.get(`/auth/check?token=${token}`);
  return httpRequest.get(`/auth/check?token=${token}&type=${type}`);
};

export const resetPassword = async (
  values: ResetPassword,
  token: string,
  type: ClientType,
) => {
  if (type === 'CLIENT') {
    return httpRequest.post('/auth/reset', { ...values, token, type });
  }

  return httpRequest.post('/auth/reset', { ...values, token });
};

import React from 'react';

import { styled, Typography } from '@material-ui/core';

interface HeaderProps {
  title: string;
  description: string;
}

export function Header({ title, description }: HeaderProps) {
  return (
    <Wrapper>
      <HeaderTitle variant="h5">{title}</HeaderTitle>
      <HeaderDescription variant="caption">{description}</HeaderDescription>
    </Wrapper>
  );
}

const Wrapper = styled('header')({
  backgroundColor: '#F5F5F5',
  textAlign: 'center',
  padding: '3rem 0',
});

const HeaderTitle = styled(Typography)({});
const HeaderDescription = styled(Typography)({});

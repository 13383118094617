import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core';

import { registerEstablishment } from '../../../api/register';
import { Establishment } from '../../../types/Establishment';
import { ContactNumber } from '../../common/ContactNumber';
import { FormActions } from '../../common/Form/FormActions';
import { FormAddress } from '../../common/Form/FormAddress';
import { FormGroup } from '../../common/Form/FormGroup';
import { FormItem } from '../../common/Form/FormItem';
import { FormSectionHeader } from '../../common/Form/FormSectionHeader';
import { RegisterSuccessMessage } from '../RegisterSuccessMessage';
import { CancelButton, SubmitButton } from '../../common/Buttons';
import { Declaration } from '../../common/Declaration';

export function RegisterFormEstablishment() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  async function handleSubmit(
    values: Establishment,
    { resetForm, setErrors }: FormikHelpers<Establishment>,
  ) {
    setIsSubmitting(true);

    try {
      await registerEstablishment(values);
      setIsSuccess(true);
      resetForm();
    } catch (error) {
      const errors = error.response.data.errors;

      if (typeof errors !== 'undefined') {
        const parsedErrors = errors.reduce((outputObject: any, item: any) => {
          outputObject[item.param] = item.msg;

          return outputObject;
        }, {});

        setErrors(parsedErrors);
        return;
      }

      enqueueSnackbar('Internal Server Error');
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleCancel() {
    history.push('/register/checkpoint');
  }

  function initializeValues(): Establishment {
    const initialValues: Establishment = {
      name: '',
      owner: '',
      mobileNumber: '',
      email: '',
      province: '',
      municipality: '',
      barangay: '',
      street1: '',
      street2: '',
      type: 'NON-GOVERNMENT AGENCY',
    };

    return Object.assign({}, initialValues);
  }

  function initializeValidation(): yup.ObjectSchema {
    return yup.object<Establishment>({
      name: yup.string().required('Field is required'),
      owner: yup.string().required('Field is required'),
      mobileNumber: yup
        .string()
        .matches(/\d{10}/, 'You have entered an invalid mobile number')
        .required('Field is required'),
      email: yup.string().required('Field is required'),
      province: yup.string().required('Field is required'),
      municipality: yup.string().required('Field is required'),
      barangay: yup.string().required('Field is required'),
      street1: yup.string().optional(),
      street2: yup.string().optional(),
      type: yup.string(),
    });
  }

  return isSuccess ? (
    <RegisterSuccessMessage />
  ) : (
    <Formik
      initialValues={initializeValues()}
      validationSchema={initializeValidation()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormSectionHeader variant="caption">
            Establishment Information
          </FormSectionHeader>

          <FormItem>
            <TextField
              name="name"
              variant="outlined"
              size="small"
              label="Name of Establishment *"
              margin="dense"
              error={typeof errors.name !== 'undefined' && touched.name}
              helperText={
                typeof errors.name !== 'undefined' && touched.name
                  ? errors.name
                  : ''
              }
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              fullWidth
            />
          </FormItem>

          <FormItem>
            <TextField
              name="owner"
              variant="outlined"
              size="small"
              label="Owner/Contact Person *"
              margin="dense"
              error={typeof errors.owner !== 'undefined' && touched.owner}
              helperText={
                typeof errors.owner !== 'undefined' && touched.owner
                  ? errors.owner
                  : ''
              }
              value={values.owner}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              fullWidth
            />
          </FormItem>

          <FormGroup>
            <FormItem>
              <TextField
                name="email"
                variant="outlined"
                size="small"
                label="Email Address *"
                margin="dense"
                error={typeof errors.email !== 'undefined' && touched.email}
                helperText={
                  typeof errors.email !== 'undefined' && touched.email
                    ? errors.email
                    : ''
                }
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
            <FormItem>
              <FormControl
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
              >
                <InputLabel htmlFor="mobile-number">
                  Mobiler Number *
                </InputLabel>
                <OutlinedInput
                  inputComponent={ContactNumber}
                  name="mobileNumber"
                  label="Mobile Number *"
                  id="mobile-number"
                  error={
                    typeof errors.mobileNumber !== 'undefined' &&
                    touched.mobileNumber
                  }
                  value={values.mobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  startAdornment={'+63'}
                  aria-describedby="mobile-number-text"
                />
                {typeof errors.mobileNumber !== 'undefined' &&
                  touched.mobileNumber && (
                    <FormHelperText id="mobile-number-text" error>
                      {errors.mobileNumber}
                    </FormHelperText>
                  )}
              </FormControl>
            </FormItem>
          </FormGroup>

          <FormAddress disabled={isSubmitting} />

          <FormGroup>
            <FormItem>
              <TextField
                name="street1"
                variant="outlined"
                size="small"
                label="Address Line 1"
                margin="dense"
                error={typeof errors.street1 !== 'undefined' && touched.street1}
                helperText={
                  typeof errors.street1 !== 'undefined' && touched.street1
                    ? errors.street1
                    : ''
                }
                value={values.street1}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
            <FormItem>
              <TextField
                name="street2"
                variant="outlined"
                size="small"
                label="Address Line 2"
                margin="dense"
                error={typeof errors.street2 !== 'undefined' && touched.street2}
                helperText={
                  typeof errors.street2 !== 'undefined' && touched.street2
                    ? errors.street2
                    : ''
                }
                value={values.street2}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
          </FormGroup>

          <Declaration variant="caption">
            By clicking submit, I agree that I have read and accepted the
            FastPass{' '}
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Declaration>

          <FormActions>
            {isSubmitting ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <CancelButton variant="text" onClick={handleCancel}>
                  Cancel
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Submit
                </SubmitButton>
              </>
            )}
          </FormActions>
        </form>
      )}
    </Formik>
  );
}

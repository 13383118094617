import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import { ContentLoader } from './ContentLoader';

interface Props {
  path: any;
}

export function ContentDisplay({ path }: Props) {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(path)
      .then((response) => response.text())
      .then((text) => {
        setIsLoading(false);
        setContent(text);
      });
  }, [content]);

  return isLoading ? (
    <ContentLoader />
  ) : (
    <ReactMarkdown allowDangerousHtml>{content}</ReactMarkdown>
  );
}

import dayjs from 'dayjs';
import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as yup from 'yup';

import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  styled,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { getAgencies, getSchools, registerClient } from '../../../api/register';
import { registrationIndividualType } from '../../../atoms/RegistrationIndividualType';
import IdTypes from '../../../contents/_json/misc_idTypes.json';
import { AddressBase } from '../../../types';
import { Client, RegistrationIndividualType } from '../../../types/Client';
import { ContactNumber } from '../../common/ContactNumber';
import { FormActions } from '../../common/Form/FormActions';
import { FormAddress } from '../../common/Form/FormAddress';
import { FormGroup } from '../../common/Form/FormGroup';
import { FormItem } from '../../common/Form/FormItem';
import { FormSectionHeader } from '../../common/Form/FormSectionHeader';
import { FormSelect } from '../../common/Form/FormSelect';
import { RegisterSuccessMessage } from '../RegisterSuccessMessage';
import { ImageUploader } from '../../common/ImageUploader';
import { CancelButton, SubmitButton } from '../../common/Buttons';
import { Declaration } from '../../common/Declaration';

export function RegisterFormIndividual() {
  const [type, setType] = useRecoilState<RegistrationIndividualType>(
    registrationIndividualType,
  );
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoadingAgencies, setIsLoadingAgencies] = useState<boolean>(true);
  const [agencies, setAgencies] = useState<AddressBase[]>([]);

  useEffect(() => {
    const fetchAgencies = async () => {
      setIsLoadingAgencies(true);

      try {
        const result = await getAgencies();
        setAgencies(result.data);
      } catch (error) {
        enqueueSnackbar('Internal Server Error');
      } finally {
        setIsLoadingAgencies(false);
      }
    };

    if (type === 'EMPLOYEE') fetchAgencies();
  }, []);

  useEffect(() => {
    const fetchSchools = async () => {
      setIsLoadingAgencies(true);

      try {
        const result = await getSchools();
        setAgencies(result.data);
      } catch (error) {
        enqueueSnackbar('Internal Server Error');
      } finally {
        setIsLoadingAgencies(false);
      }
    };

    if (type === 'STUDENT') fetchSchools();
  }, []);

  async function handleSubmit(
    values: Client,
    { resetForm, setErrors }: FormikHelpers<Client>,
  ) {
    setIsSubmitting(true);

    const formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (key === 'birthDate') {
        formData.append(key, dayjs(value).format('YYYY-MM-DD'));

        return;
      }

      formData.append(key, value);
    });

    try {
      await registerClient(formData);
      setIsSuccess(true);
      resetForm();
    } catch (error) {
      const errors = error.response.data.errors;

      if (typeof errors !== 'undefined') {
        const parsedErrors = errors.reduce((outputObject: any, item: any) => {
          outputObject[item.param] = item.msg;

          return outputObject;
        }, {});

        setErrors(parsedErrors);
        return;
      }

      enqueueSnackbar('Internal Server Error');
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleCancel() {
    setType(null);
  }

  function initializeValues(): Client {
    const initialValues: Client = {
      establishmentId: '',
      position: '',
      alias: '',
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      birthDate: new Date(),
      sex: 'Female',
      email: '',
      mobileNumber: '',
      province: '',
      municipality: '',
      barangay: '',
      idType: '',
      idNumber: '',
      idPhoto: null,
      idSelfiePhoto: null,
      type,
    };

    return Object.assign({}, initialValues);
  }

  function initializeValidation(): yup.ObjectSchema {
    return yup.object<Client>({
      establishmentId: yup.string().when('type', {
        is: 'EMPLOYEE',
        then: yup.string().required('Field is required'),
      }),
      position: yup.string().when('type', {
        is: 'EMPLOYEE',
        then: yup.string().required('Field is requried'),
      }),
      alias: yup.string().required('Field is required'),
      firstName: yup.string().required('Field is required'),
      lastName: yup.string().required('Field is required'),
      middleName: yup.string().optional(),
      suffix: yup.string().optional(),
      birthDate: yup.date().required('Field is required'),
      sex: yup
        .mixed()
        .required('Field is required')
        .oneOf(['Male', 'Female'], 'You hav entered an invalid sex'),
      email: yup
        .string()
        .email('You have entered an invalid email address.')
        .required('Field is required'),
      mobileNumber: yup
        .string()
        .matches(/\d{10}/, 'You have entered an invalid mobile number')
        .required('Field is required'),
      province: yup.string().required('Field is required'),
      municipality: yup.string().required('Field is required'),
      barangay: yup.string().required('Field is required'),
      idType: yup.string().required('Field is requried'),
      idNumber: yup.string().required('Field is required'),
      idPhoto: yup.mixed().required('You must upload a photo'),
      idSelfiePhoto: yup.mixed().required('You must upload a selfie'),
      type: yup.string(),
    });
  }

  return isSuccess ? (
    <RegisterSuccessMessage />
  ) : (
    <Formik
      initialValues={initializeValues()}
      validationSchema={initializeValidation()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          {(type === 'EMPLOYEE' || type === 'STUDENT') && (
            <>
              <FormSectionHeader variant="caption">
                {type === 'EMPLOYEE'
                  ? 'Agency Information'
                  : 'School Information'}
              </FormSectionHeader>

              {isLoadingAgencies ? (
                <AgencyInformation>
                  <CircularProgress size={25} />
                </AgencyInformation>
              ) : (
                <>
                  <FormSelect
                    id="agencies-list"
                    name="establishmentId"
                    label={type === 'EMPLOYEE' ? 'Agency *' : 'School *'}
                    value={
                      typeof values.establishmentId !== 'undefined'
                        ? values.establishmentId
                        : ''
                    }
                    error={
                      typeof errors.establishmentId !== 'undefined' &&
                      touched.establishmentId
                    }
                    helperText={
                      typeof errors.establishmentId !== 'undefined' &&
                      touched.establishmentId
                        ? errors.establishmentId
                        : ''
                    }
                    data={agencies}
                    selectValue="id"
                    selectText="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                  />
                  {type === 'EMPLOYEE' && (
                    <FormItem>
                      <TextField
                        name="position"
                        variant="outlined"
                        size="small"
                        label="Position *"
                        margin="dense"
                        error={
                          typeof errors.position !== 'undefined' &&
                          touched.position
                        }
                        helperText={
                          typeof errors.position !== 'undefined' &&
                          touched.position
                            ? errors.position
                            : ''
                        }
                        value={values.position}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        fullWidth
                      />
                    </FormItem>
                  )}
                </>
              )}
            </>
          )}

          <FormSectionHeader variant="caption">
            Personal Information
          </FormSectionHeader>

          <FormItem>
            <TextField
              name="alias"
              variant="outlined"
              size="small"
              label="Alias *"
              margin="dense"
              error={typeof errors.alias !== 'undefined' && touched.alias}
              helperText={
                typeof errors.alias !== 'undefined' && touched.alias
                  ? errors.alias
                  : ''
              }
              value={values.alias}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              fullWidth
            />
          </FormItem>

          <FormGroup>
            <FormItem>
              <TextField
                name="firstName"
                variant="outlined"
                size="small"
                label="First Name *"
                margin="dense"
                error={
                  typeof errors.firstName !== 'undefined' && touched.firstName
                }
                helperText={
                  typeof errors.firstName !== 'undefined' && touched.firstName
                    ? errors.firstName
                    : ''
                }
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
            <FormItem>
              <TextField
                name="lastName"
                variant="outlined"
                size="small"
                label="Last Name *"
                margin="dense"
                error={
                  typeof errors.lastName !== 'undefined' && touched.lastName
                }
                helperText={
                  typeof errors.lastName !== 'undefined' && touched.lastName
                    ? errors.lastName
                    : ''
                }
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
          </FormGroup>

          <FormGroup>
            <FormItem>
              <TextField
                name="middleName"
                variant="outlined"
                size="small"
                label="Middle Name"
                margin="dense"
                value={values.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>

            <FormItem>
              <TextField
                name="suffix"
                variant="outlined"
                size="small"
                label="Suffix (Jr, Sr, III)"
                margin="dense"
                value={values.suffix}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
          </FormGroup>

          <FormGroup>
            <FormItem>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="yyyy-MM-dd"
                  onChange={(value) => setFieldValue('birthDate', value)}
                  value={values.birthDate}
                  inputVariant="outlined"
                  size="small"
                  margin="dense"
                  label="Date of Birth"
                  fullWidth
                  autoOk
                  disabled={isSubmitting}
                />
              </MuiPickersUtilsProvider>
            </FormItem>

            <FormSelect
              id="sex-list"
              name="sex"
              label="Sex *"
              value={values.sex}
              data={['Male', 'Female']}
              error={typeof errors.sex !== 'undefined' && touched.sex}
              helperText={
                typeof errors.sex !== 'undefined' && touched.sex
                  ? errors.sex
                  : null
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
          </FormGroup>

          <FormGroup>
            <FormItem>
              <TextField
                name="email"
                variant="outlined"
                size="small"
                label="Email Address *"
                margin="dense"
                error={typeof errors.email !== 'undefined' && touched.email}
                helperText={
                  typeof errors.email !== 'undefined' && touched.email
                    ? errors.email
                    : ''
                }
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>

            <FormItem>
              <FormControl
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
              >
                <InputLabel htmlFor="mobile-number">
                  Mobiler Number *
                </InputLabel>
                <OutlinedInput
                  inputComponent={ContactNumber}
                  name="mobileNumber"
                  label="Mobile Number *"
                  id="mobile-number"
                  error={
                    typeof errors.mobileNumber !== 'undefined' &&
                    touched.mobileNumber
                  }
                  value={values.mobileNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  startAdornment={'+63'}
                  aria-describedby="mobile-number-text"
                />
                {typeof errors.mobileNumber !== 'undefined' &&
                  touched.mobileNumber && (
                    <FormHelperText id="mobile-number-text" error>
                      {errors.mobileNumber}
                    </FormHelperText>
                  )}
              </FormControl>
            </FormItem>
          </FormGroup>

          <FormSectionHeader variant="caption">
            Address Information
          </FormSectionHeader>

          <FormAddress disabled={isSubmitting} />

          <FormSectionHeader variant="caption">
            Identification Documents
          </FormSectionHeader>

          <FormGroup>
            <FormSelect
              id="id-types-list"
              name="idType"
              label="ID Type *"
              value={values.idType}
              data={IdTypes.data}
              error={typeof errors.idType !== 'undefined' && touched.idType}
              helperText={
                typeof errors.idType !== 'undefined' && touched.idType
                  ? errors.idType
                  : null
              }
              onChange={handleChange}
              selectText="name"
              selectValue="name"
              onBlur={handleBlur}
              disabled={isSubmitting}
            />

            <FormItem>
              <TextField
                name="idNumber"
                variant="outlined"
                size="small"
                label="ID Number *"
                margin="dense"
                error={
                  typeof errors.idNumber !== 'undefined' && touched.idNumber
                }
                helperText={
                  typeof errors.idNumber !== 'undefined' && touched.idNumber
                    ? errors.idNumber
                    : ''
                }
                value={values.idNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                fullWidth
              />
            </FormItem>
          </FormGroup>

          <ImageUploader
            label="Photo of ID Card"
            name="idPhoto"
            error={typeof errors.idPhoto !== 'undefined' && touched.idPhoto}
            helperText={
              typeof errors.idPhoto !== 'undefined' && touched.idPhoto
                ? errors.idPhoto
                : ''
            }
          />

          <ImageUploader
            label="Take a Selfie with your ID Card"
            name="idSelfiePhoto"
            error={
              typeof errors.idSelfiePhoto !== 'undefined' &&
              touched.idSelfiePhoto
            }
            helperText={
              typeof errors.idSelfiePhoto !== 'undefined' &&
              touched.idSelfiePhoto
                ? errors.idSelfiePhoto
                : ''
            }
          />

          <Declaration variant="caption">
            By clicking submit, I agree that I have read and accepted the
            FastPass{' '}
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            .
          </Declaration>

          <FormActions>
            {isSubmitting ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <CancelButton variant="text" onClick={handleCancel}>
                  Cancel
                </CancelButton>
                <SubmitButton type="submit" variant="contained" color="primary">
                  Submit
                </SubmitButton>
              </>
            )}
          </FormActions>
        </form>
      )}
    </Formik>
  );
}

const AgencyInformation = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem 0',
});

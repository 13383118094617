import React from 'react';

import { styled } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export function ContentLoader() {
  return (
    <Wrapper>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Wrapper>
  );
}

const Wrapper = styled('section')({
  padding: '1rem 0',
});

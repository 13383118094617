import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { About } from './screens/About';
import { Faq } from './screens/Faq';
import { Home } from './screens/Home';
import { NotFound } from './screens/NotFound';
import { PrivacyPolicy } from './screens/PrivacyPolicy';
import { Recover } from './screens/Recover';
import { Register } from './screens/Register';
import { Reset } from './screens/Reset';

export function AppRoutes() {
  return (
    <Switch>
      <Route path="/register" render={() => <Register />} />
      <Route exact path="/" render={() => <Home />} />
      <Route exact path="/about" render={() => <About />} />
      <Route exact path="/privacy-policy" render={() => <PrivacyPolicy />} />
      <Route exact path="/faq" render={() => <Faq />} />
      <Route exact path="/reset" render={() => <Reset />} />
      <Route exact path="/recover" render={() => <Recover />} />
      <Route component={NotFound} />
    </Switch>
  );
}

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { styled, Typography } from '@material-ui/core';
import { QuestionAnswerOutlined } from '@material-ui/icons';

import { Header } from '../components/common/Header';
import { Content } from '../components/content';
import { ContentDisplay } from '../components/content/ContentDisplay';
import WhatIsPath from '../contents/faq/1_WhatIs.md';
import WhoWillPath from '../contents/faq/2_WhoWillUse.md';
import HowToPath from '../contents/faq/3_HowToUse.md';
import WhatArePath from '../contents/faq/4_WhatAreTheRequirements.md';
import { DefaultLayout } from '../layouts/DefaultLayout';

export function Faq() {
  const [questions] = useState([
    { id: 1, title: 'What is FastPass?', content: WhatIsPath },
    { id: 2, title: 'Who will use?', content: WhoWillPath },
    { id: 3, title: 'How to use?', content: HowToPath },
    { id: 4, title: 'What are the requirements?', content: WhatArePath },
  ]);

  return (
    <DefaultLayout>
      <Helmet>
        <title>Frequently Asked Questions</title>
      </Helmet>

      <Header
        title="Need help?"
        description="Browser through our frequently asked questions"
      />

      <Content align="justify" width={768}>
        {questions.map((question) => {
          return (
            <QuestionItem key={question.id}>
              <QuestionHeader>
                <QuestionAnswerOutlined />

                <QuestionHeaderTitle variant="h6">
                  {question.title}
                </QuestionHeaderTitle>
              </QuestionHeader>

              <ContentDisplay path={question.content} />
            </QuestionItem>
          );
        })}
      </Content>
    </DefaultLayout>
  );
}

const QuestionItem = styled('article')(({ theme }) => ({
  '& a': {
    textDecoration: 'none',
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
}));

const QuestionHeader = styled('section')({
  display: 'flex',
  alignItems: 'center',
});

const QuestionHeaderTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  marginLeft: '5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  },
}));

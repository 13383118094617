import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import {
  getBarangays,
  getMuncipality,
  getMunicipalities,
  getProvince,
  getProvinces,
} from '../../../api';
import { AddressBase } from '../../../types';
import { FormSelect } from './FormSelect';

interface Props {
  disabled: boolean;
}

export function FormAddress({ disabled }: Props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext<{
    province: string;
    municipality: string;
    barangay: string;
  }>();

  const [provinceList, setProvinceList] = useState<AddressBase[]>([]);
  const [municipalityList, setMunicipalityList] = useState<AddressBase[]>([]);
  const [barangayList, setBarangayList] = useState<string[]>([]);

  const [provinceId, setProvinceId] = useState<string | null>(null);

  useEffect(() => {
    setProvinceList(getProvinces());
  }, []);

  useEffect(() => {
    if (values.province !== '') {
      const data = getProvince(values.province);

      if (data !== null) {
        const { id } = data;

        setProvinceId(id);
        setMunicipalityList(getMunicipalities(id));
        setFieldValue('municipality', '');
      }
    }
  }, [values.province]);

  useEffect(() => {
    if (values.municipality !== '') {
      if (provinceId !== null) {
        const data = getMuncipality(provinceId, values.municipality);

        if (data !== null) {
          const { id } = data;

          setBarangayList(getBarangays(provinceId, id));
          setFieldValue('barangay', '');
        }
      }
    }
  }, [values.municipality]);

  return (
    <>
      <FormSelect
        id="province-lisst"
        name="province"
        label="Province *"
        value={values.province}
        data={provinceList}
        error={typeof errors.province !== 'undefined' && touched.province}
        helperText={
          typeof errors.province !== 'undefined' && touched.province
            ? errors.province
            : null
        }
        onChange={handleChange}
        onBlur={handleBlur}
        selectText="name"
        selectValue="name"
        disabled={provinceList.length === 0 || disabled}
      />
      {values.province !== '' && (
        <FormSelect
          id="municipality-lisst"
          name="municipality"
          label="City/Municipality *"
          value={values.municipality}
          data={municipalityList}
          error={
            typeof errors.municipality !== 'undefined' && touched.municipality
          }
          helperText={
            typeof errors.municipality !== 'undefined' && touched.municipality
              ? errors.municipality
              : null
          }
          onChange={handleChange}
          onBlur={handleBlur}
          selectText="name"
          selectValue="name"
          disabled={values.province === '' || disabled}
        />
      )}
      {values.municipality !== '' && (
        <FormSelect
          id="barangay-lisst"
          name="barangay"
          label="Barangay *"
          value={values.barangay}
          data={barangayList}
          error={typeof errors.barangay !== 'undefined' && touched.barangay}
          helperText={
            typeof errors.barangay !== 'undefined' && touched.barangay
              ? errors.barangay
              : null
          }
          onChange={handleChange}
          onBlur={handleBlur}
          selectText="name"
          selectValue="name"
          disabled={values.municipality === '' || disabled}
        />
      )}
    </>
  );
}

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Card, CardContent, Grid, styled, Typography } from '@material-ui/core';

import { Content } from '../components/content';
import { ContentDisplay } from '../components/content/ContentDisplay';
import AboutPath from '../contents/About.md';
import { DefaultLayout } from '../layouts/DefaultLayout';

export function About() {
  const [team] = useState([
    {
      id: 1,
      name: 'Michael John C. Maquiling',
      position: 'Team Leader ',
      imgUrl:
        'https://fastpass-public.s3-ap-southeast-1.amazonaws.com/assets/dp_mcm.jpg',
    },
    {
      id: 2,
      name: 'Ryan Jan R. Borja',
      position: 'Developer ',
      imgUrl:
        'https://fastpass-public.s3-ap-southeast-1.amazonaws.com/assets/dp_rrb.jpg',
    },
    {
      id: 3,
      name: 'Enrique L. Rimando',
      position: 'Developer ',
      imgUrl:
        'https://fastpass-public.s3-ap-southeast-1.amazonaws.com/assets/dp_elr.jpg',
    },
    {
      id: 4,
      name: 'Grant Kim P. Castillones',
      position: 'Designer',
      imgUrl:
        'https://fastpass-public.s3-ap-southeast-1.amazonaws.com/assets/dp_gpc.jpg',
    },
    {
      id: 5,
      name: 'Raymond Ardee F. Soriano',
      position: 'Infrastracture and Support',
      imgUrl:
        'https://fastpass-public.s3-ap-southeast-1.amazonaws.com/assets/dp_rfs.jpg',
    },
  ]);

  return (
    <DefaultLayout>
      <Helmet>
        <title>About FastPass</title>
      </Helmet>
      <AboutDescription>
        <Content align="center" width={468}>
          <Typography variant="h5">About FastPass</Typography>
          <ContentDisplay path={AboutPath} />
        </Content>
      </AboutDescription>

      <AboutTeam>
        <Typography variant="h5">The Team</Typography>

        <Content align="center" width={1024}>
          <Grid container spacing={2} justify="center">
            {team.map((item) => {
              return (
                <Grid key={item.id} sm={4} item>
                  <AboutTeamItem>
                    <AboutTeamImage src={item.imgUrl} alt={item.name} />

                    <CardContent>
                      <Typography variant="body1" component="h1">
                        {item.name}
                      </Typography>
                      <Typography variant="caption" component="p">
                        {item.position}
                      </Typography>
                    </CardContent>
                  </AboutTeamItem>
                </Grid>
              );
            })}
          </Grid>
        </Content>
      </AboutTeam>
    </DefaultLayout>
  );
}

const AboutDescription = styled('section')({
  backgroundColor: '#F5F5F5',
  padding: '3rem 0',
});

const AboutTeam = styled('section')({
  padding: '3rem 0',
  textAlign: 'center',
});

const AboutTeamItem = styled(Card)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  border: 'none',
  boxShadow: 'none',
});

const AboutTeamImage = styled('img')({
  width: '150px',
});

import { Button, styled } from '@material-ui/core';

export const RegisterAction = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '0.75rem',
  margin: '0 auto',
  marginTop: '1rem',
  borderRadius: '2rem',

  '& svg': {
    fontSize: 'inherit',
    marginRight: '5px',
  },
});

import { Fade } from '@material-ui/core';
import React from 'react';
import { FormWrapper } from '../../components/common/Form/FormWrapper';
import { HeaderForm } from '../../components/common/Header/HeaderForm';
import { RegisterEstablishmentBanner } from '../../components/register/RegisterEstablishmentBanner';
import { RegisterFormEstablishment } from '../../components/register/RegisterForm/RegisterFormEstablishment';
import { CenteredLayout } from '../../layouts/CenteredLayout';

export function Establishments() {
  return (
    <CenteredLayout>
      <Fade in={true}>
        <FormWrapper>
          <HeaderForm title={`Register as ESTABLISHMENT`} />
          <RegisterEstablishmentBanner />
          <RegisterFormEstablishment />
        </FormWrapper>
      </Fade>
    </CenteredLayout>
  );
}

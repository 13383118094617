import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, styled } from '@material-ui/core';

interface RegisterButtonProps {
  size: 'small' | 'medium' | 'large';
}

export function RegisterButton({ size }: RegisterButtonProps) {
  const history = useHistory();

  function handleRegister() {
    history.push('/register');
  }
  return (
    <Wrapper size={size} onClick={handleRegister}>
      Register
    </Wrapper>
  );
}

const Wrapper = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  borderRadius: '2rem',
  backgroundColor: theme.palette.success.main,
  '&:hover': {
    backgroundColor: theme.palette.success.light,
  },
  height: '42px',
  width: '180px',
  [theme.breakpoints.down('sm')]: {
    height: '32px',
    width: '120px',
  },
}));

import { styled } from '@material-ui/core';

export const FormGroup = styled('section')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',

  [theme.breakpoints.down('xs')]: {
    display: 'block',
  },
}));

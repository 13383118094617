import { Formik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { CircularProgress, TextField } from '@material-ui/core';

import { resetPassword, ResetPassword } from '../../api/reset';
import { FormActions } from '../common/Form/FormActions';
import { FormItem } from '../common/Form/FormItem';
import { ResetSuccessMessage } from './ResetSuccessMessage';
import { ResetQueryParams } from '../../types';
import { SubmitButton } from '../common/Buttons';

export function ResetPasswordForm() {
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit(
    values: ResetPassword,
    { setErrors }: FormikHelpers<ResetPassword>,
  ) {
    const { token, type }: ResetQueryParams = qs.parse(search);
    setIsSubmitting(true);

    if (typeof type === 'undefined' || typeof token === 'undefined') {
      return enqueueSnackbar('Internal Server Error');
    }

    try {
      await resetPassword(values, token, type);
      setIsSuccess(true);
    } catch (error) {
      const errors = error.response.data.errors;

      if (typeof errors !== 'undefined') {
        const parsedErrors = errors.reduce((outputObject: any, item: any) => {
          outputObject[item.param] = item.msg;

          return outputObject;
        }, {});

        setErrors(parsedErrors);
        return;
      }

      enqueueSnackbar('Internal Server Error');
    } finally {
      setIsSubmitting(false);
    }
  }

  function initializeValues(): ResetPassword {
    const initalValues: ResetPassword = {
      password: '',
      confirmPassword: '',
    };

    return Object.assign({}, initalValues);
  }

  function initializeValidation(): yup.ObjectSchema {
    return yup.object<ResetPassword>({
      password: yup
        .string()
        .required('Field is required')
        .min(8, 'Password must be at least 8 characters'),
      confirmPassword: yup
        .string()
        .required('Field is required')
        .when('password', {
          is: (val) => (val && val.length > 0 ? true : false),
          then: yup
            .string()
            .oneOf([yup.ref('password')], 'Password did not match'),
        }),
    });
  }

  return isSuccess ? (
    <ResetSuccessMessage />
  ) : (
    <Formik
      initialValues={initializeValues()}
      validationSchema={initializeValidation()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormItem>
            <TextField
              type="password"
              name="password"
              variant="outlined"
              size="small"
              label="Password *"
              margin="dense"
              error={typeof errors.password !== 'undefined' && touched.password}
              helperText={
                typeof errors.password !== 'undefined' && touched.password
                  ? errors.password
                  : ''
              }
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              fullWidth
            />
          </FormItem>

          <FormItem>
            <TextField
              type="password"
              name="confirmPassword"
              variant="outlined"
              size="small"
              label="Confirm Password *"
              margin="dense"
              error={
                typeof errors.confirmPassword !== 'undefined' &&
                touched.confirmPassword
              }
              helperText={
                typeof errors.confirmPassword !== 'undefined' &&
                touched.confirmPassword
                  ? errors.confirmPassword
                  : ''
              }
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              fullWidth
            />
          </FormItem>

          <FormActions>
            {isSubmitting ? (
              <CircularProgress size={25} />
            ) : (
              <SubmitButton type="submit" variant="contained" color="primary">
                Submit
              </SubmitButton>
            )}
          </FormActions>
        </form>
      )}
    </Formik>
  );
}

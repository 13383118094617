import barangays from '../contents/_json/addr_barangays.json';
import municipalities from '../contents/_json/addr_municipalities.json';
import provinces from '../contents/_json/addr_provinces.json';
import { AddressBase } from '../types';

export const getProvinces = (): AddressBase[] => {
  const regCode = '01';

  return provinces.data.reduce((arrayOutput: AddressBase[], item) => {
    if (item.regCode === regCode) {
      const province: AddressBase = {
        id: item.provCode,
        name: item.provDesc,
      };

      arrayOutput.push(province);
      return arrayOutput;
    }

    return arrayOutput;
  }, []);
};

export const getProvince = (province: string): AddressBase | null => {
  const data = provinces.data.find((item) => item.provDesc === province);

  if (typeof data !== 'undefined') {
    return {
      id: data.provCode,
      name: data.provDesc,
    };
  }

  return null;
};

export const getMunicipalities = (provinceId: string) => {
  return municipalities.data.reduce((arrayOutput: AddressBase[], item) => {
    if (item.provCode === provinceId) {
      const municipality: AddressBase = {
        id: item.citymunCode,
        name: item.citymunDesc,
      };

      arrayOutput.push(municipality);

      return arrayOutput;
    }

    return arrayOutput;
  }, []);
};

export const getMuncipality = (
  provinceId: string,
  municipality: string,
): AddressBase | null => {
  const data = municipalities.data.find(
    (item) => item.provCode === provinceId && item.citymunDesc === municipality,
  );

  if (typeof data !== 'undefined') {
    return {
      id: data.citymunCode,
      name: data.citymunDesc,
    };
  }

  return null;
};

export const getBarangays = (provinceId: string, municipalityId: string) => {
  const list = barangays as any;

  return list.data.reduce((arrayOutput: AddressBase[], item: any) => {
    if (item.provCode === provinceId && item.citymunCode === municipalityId) {
      const barangay: AddressBase = {
        id: item.brgyCode,
        name: item.brgyDesc.toUpperCase(),
      };

      arrayOutput.push(barangay);
      return arrayOutput;
    }

    return arrayOutput;
  }, []);
};

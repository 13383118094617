import React from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@material-ui/core';

import { Message } from '../common/Message';
import { RegisterCaption } from './RegisterCaption';
import { SuccessIcon } from '../common/Icons';

export function RegisterSuccessMessage() {
  return (
    <Wrapper>
      <SuccessIcon />

      <Message variant="body1">
        Your registration is now pending for approval. Please take note that
        once approved, your QR Code and credentials will be sent via email.
      </Message>

      <RegisterCaption variant="caption">
        Having problems? Please check our <Link to="/faq">FAQs</Link> for more
        information.
      </RegisterCaption>
    </Wrapper>
  );
}

const Wrapper = styled('section')(({ theme }) => ({
  textAlign: 'center',

  '& svg': {
    fontSize: '5rem',
    color: theme.palette.primary.main,
  },
}));

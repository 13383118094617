import React from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import { FormItem } from './FormItem';

interface Props {
  id: string;
  name: string;
  label: string;
  value: string;
  error: boolean | undefined;
  helperText: string | null;
  onChange: any;
  onBlur: any;
  selectValue?: string;
  selectText?: string;
  disabled?: boolean;
  data: any[];
}

export function FormSelect({
  id,
  name,
  label,
  disabled,
  value,
  error,
  helperText,
  onChange,
  onBlur,
  selectValue,
  selectText,
  data,
}: Props) {
  return (
    <FormItem>
      <FormControl variant="outlined" size="small" margin="dense" fullWidth>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          name={name}
          label={label}
          value={value}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          aria-describedby={id}
          disabled={typeof disabled !== 'undefined' && disabled}
        >
          {data.map((data, index) => {
            const text =
              typeof selectText === 'undefined' ? data : data[selectText];
            const value =
              typeof selectValue === 'undefined' ? data : data[selectValue];

            return (
              <MenuItem key={index} value={value}>
                {text}
              </MenuItem>
            );
          })}
        </Select>
        {helperText !== null && (
          <FormHelperText id={id} error={error}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </FormItem>
  );
}

import { useSnackbar } from 'notistack';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { CircularProgress, Fade, styled } from '@material-ui/core';

import { checkResetPassword } from '../api/reset';
import { FormWrapper } from '../components/common/Form/FormWrapper';
import { HeaderForm } from '../components/common/Header/HeaderForm';
import { ResetPasswordForm } from '../components/reset/ResetPasswordForm';
import { ResetTokenInvalidMessage } from '../components/reset/ResetTokenInvalidMessage';
import { CenteredLayout } from '../layouts/CenteredLayout';
import { ResetQueryParams } from '../types';

export function Reset() {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [isChecking, setIsChecking] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { search } = location;
      setIsChecking(true);

      const { token, type }: ResetQueryParams = qs.parse(search);

      try {
        if (typeof token === 'undefined') return setIsValid(false);
        if (typeof type === 'undefined') return setIsValid(false);
        if (type !== 'CLIENT' && type !== 'USER') return setIsValid(false);

        const result = await checkResetPassword(token, type);
        setIsValid(result.data.isValid);
      } catch (error) {
        enqueueSnackbar('Internal Server Error');
      } finally {
        setIsChecking(false);
      }
    };

    fetchData();
  }, []);

  return (
    <CenteredLayout>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <Fade in={true}>
        <FormWrapper>
          <HeaderForm title="Reset Password" />

          {isChecking ? (
            <Loading>
              <CircularProgress size={25} />
            </Loading>
          ) : isValid ? (
            <ResetPasswordForm />
          ) : (
            <ResetTokenInvalidMessage />
          )}
        </FormWrapper>
      </Fade>
    </CenteredLayout>
  );
}

const Loading = styled('section')({
  textAlign: 'center',
  padding: '1rem 0',
});

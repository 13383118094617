import React from 'react';

import { styled, Typography } from '@material-ui/core';

interface Props {
  title: string;
  caption?: string;
}

export function HeaderForm({ title, caption }: Props) {
  return (
    <Wrapper>
      <Typography variant="h6">{title}</Typography>
      {typeof caption !== 'undefined' ? (
        <Typography variant="caption">{caption}</Typography>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled('header')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '1rem',

  [theme.breakpoints.down('sm')]: {
    marginBottom: '0.75rem',

    '& h6': {
      fontSize: '1rem',
    },
  },
}));

import { Fade } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormWrapper } from '../components/common/Form/FormWrapper';
import { HeaderForm } from '../components/common/Header/HeaderForm';
import { RecoverPasswordForm } from '../components/recover/RecoverPasswordForm';
import { CenteredLayout } from '../layouts/CenteredLayout';

export function Recover() {
  return (
    <CenteredLayout>
      <Helmet>
        <title>Recover Password</title>
      </Helmet>
      <Fade in={true}>
        <FormWrapper>
          <HeaderForm title="Recover Password" />

          <RecoverPasswordForm />
        </FormWrapper>
      </Fade>
    </CenteredLayout>
  );
}

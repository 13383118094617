import { Collapse, IconButton, styled } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import {
  GOVERNMENT_OFFICE_LINK,
  GOVERNMENT_PROVINCIAL_LINK,
} from '../../shared/Constants';

export function RegisterEstablishmentBanner() {
  const [isAlertOpen, setIsAlertOpen] = useState(true);

  return (
    <Collapse in={isAlertOpen}>
      <Alert
        severity="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setIsAlertOpen(false);
            }}
          >
            <CloseOutlined fontSize="inherit" />
          </IconButton>
        }
      >
        <EstablishmentTypes>
          <p>
            Government Establishment? click{' '}
            <a href={GOVERNMENT_OFFICE_LINK} target="_blank" rel="noreferrer">
              here
            </a>
            .
          </p>

          <p>
            Government Provincial/Branch Office? click{' '}
            <a
              href={GOVERNMENT_PROVINCIAL_LINK}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
        </EstablishmentTypes>
      </Alert>
    </Collapse>
  );
}

const EstablishmentTypes = styled('section')(({ theme }) => ({
  '& p': {
    margin: '0',
    fontSize: '0.8rem',
  },

  '& a': {
    color: theme.palette.common.black,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

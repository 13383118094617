import { styled } from '@material-ui/core';

export const Options = styled('section')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '0.5rem',
  marginBottom: '1rem',
});

export const OptionsTrio = styled('section')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '0.5rem',
  marginBottom: '1rem',
});

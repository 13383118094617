import { Fade } from '@material-ui/core';
import React from 'react';
import { FormWrapper } from '../../components/common/Form/FormWrapper';
import { CenteredLayout } from '../../layouts/CenteredLayout';
import { HeaderForm } from '../../components/common/Header/HeaderForm';
import { FormActions } from '../../components/common/Form/FormActions';
import { CancelButton, SubmitButton } from '../../components/common/Buttons';
import { useHistory } from 'react-router-dom';
import { Declaration } from '../../components/common/Declaration';

export function UserDeclaration() {
  const history = useHistory();

  function handleDisagree() {
    history.push('/faq');
  }

  function handleAgree() {
    history.push('/register/checkpoint');
  }

  return (
    <CenteredLayout>
      <Fade in={true}>
        <FormWrapper>
          <HeaderForm title="User Declaration" />

          <Declaration variant="caption">
            I hereby certify that all information I will be submitting are true
            and complete. I understand that my failure to answer, or any false
            or misleading information given by me may be used as a ground for
            the filing of cases against me under{' '}
            <strong>Articles 171 and 172</strong> of the{' '}
            <strong>
              Revised Penal Code of the Philippines or Republic Act No. 11332
            </strong>
            , otherwise known as the{' '}
            <strong>“Law on Reporting of Communicable Disease</strong>,.
          </Declaration>

          <Declaration variant="caption">
            I hereby undertake to limit my movement and strictly follow the
            purpose of my travel and duly abide the minimum health standards and
            protocols.
          </Declaration>

          <FormActions>
            <CancelButton variant="text" onClick={handleDisagree}>
              Disagree
            </CancelButton>
            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleAgree}
            >
              Agree
            </SubmitButton>
          </FormActions>
        </FormWrapper>
      </Fade>
    </CenteredLayout>
  );
}

import { Establishment } from '../types/Establishment';
import { httpRequest } from './axios';

export const getAgencies = async () => {
  return httpRequest.get('/establishments/agencies');
};

export const getSchools = async () => {
  return httpRequest.get('/establishments/agencies?filter=schools');
};

export const registerClient = async (client: FormData) => {
  return httpRequest.post('/register/clients', client);
};

export const registerEstablishment = async (establishment: Establishment) => {
  return httpRequest.post('/register/establishments', {
    ...establishment,
    city: establishment.municipality,
  });
};

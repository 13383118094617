import React from 'react';

import { styled } from '@material-ui/core/styles';

import { Footer } from '../components/common/Footer';
import { LogoWithCaption } from '../components/common/LogoWithCaption';
import { Props } from '../shared/Props';

interface CenteredLayoutProps extends Props {
  centered?: boolean;
}

export function CenteredLayout({ children, ...props }: CenteredLayoutProps) {
  return (
    <Wrapper {...props}>
      <Header>
        <LogoWithCaption />
      </Header>
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled('section')(({ theme }: any) => ({
  ...theme.mixins.gutters(),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  minHeight: '100vh',
  overflow: 'auto',
}));

const Header = styled('header')(({ theme }) => ({
  color: theme.palette.common.white,
}));

const Content = styled('main')(({ theme }) => ({
  padding: '2rem 0',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    margin: '0 auto',
    padding: '1rem 0',
  },
}));

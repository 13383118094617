import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { styled, Typography } from '@material-ui/core';

import ImageSrc from '../assets/not_found.svg';
import { SubmitButton } from '../components/common/Buttons';

export function NotFound() {
  const history = useHistory();

  function handleHome() {
    history.push('/');
  }

  return (
    <Wrapper>
      <Helmet>
        <title>FastPass</title>
      </Helmet>

      <img src={ImageSrc} alt="Page Not Found" />

      <Description>
        <Typography variant="h5">Page Not Found</Typography>
        <Typography variant="caption">
          Looks like this page is missing. Don&apos;t worry though you can go
          back home page.
        </Typography>
      </Description>

      <SubmitButton variant="contained" color="primary" onClick={handleHome}>
        Go Back to Home
      </SubmitButton>
    </Wrapper>
  );
}

const Wrapper = styled('section')(({ theme }) => ({
  ...theme.mixins.gutters(),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',

  '& img': {
    width: '300px',
    marginBottom: '1rem',
  },
}));

const Description = styled('section')({
  textAlign: 'center',
  marginBottom: '1rem',
});

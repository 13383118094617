import React from 'react';
import { useHistory } from 'react-router-dom';

import { styled, Typography } from '@material-ui/core';

import LogoSrc from '../../assets/logo_transparent.svg';

export function LogoWithCaption() {
  const history = useHistory();

  function handleHome() {
    history.push('/');
  }

  return (
    <>
      <Image
        src={LogoSrc}
        alt="FastPass Logo"
        width="90"
        height="90"
        onClick={handleHome}
      />
      <Typography variant="h6" color="inherit">
        FastPass
      </Typography>
    </>
  );
}

const Image = styled('img')({
  cursor: 'pointer',
});

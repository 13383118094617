import { styled } from '@material-ui/core';

export const FormActions = styled('section')({
  marginTop: '1rem',
  textAlign: 'center',

  '& button': {
    marginRight: '0.75rem',
  },

  '& button:last-child': {
    marginRight: '0',
  },
});

import React from 'react';
import { useSetRecoilState } from 'recoil';

import { Typography } from '@material-ui/core';
import { PersonOutlined, WorkOutline } from '@material-ui/icons';

import { registrationIndividualType } from '../../atoms/RegistrationIndividualType';
import { RegistrationIndividualType } from '../../types/Client';
import { OptionsTrio } from '../common/Options';
import { OptionsItem } from '../common/Options/OptionsItem';

export function RegisterIndividualTypeOptions() {
  const setType = useSetRecoilState<RegistrationIndividualType>(
    registrationIndividualType,
  );

  function handleIndividual() {
    setType('INDIVIDUAL');
  }

  function handleEmployee() {
    setType('EMPLOYEE');
  }

  function handleStudent() {
    setType('STUDENT');
  }

  return (
    <OptionsTrio>
      <OptionsItem onClick={handleStudent}>
        <PersonOutlined />
        <Typography variant="caption">Student</Typography>
      </OptionsItem>
      <OptionsItem onClick={handleEmployee}>
        <WorkOutline />
        <Typography variant="caption">Employee</Typography>
      </OptionsItem>
      <OptionsItem onClick={handleIndividual}>
        <PersonOutlined />
        <Typography variant="caption">Non-Employee</Typography>
      </OptionsItem>
    </OptionsTrio>
  );
}

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, styled, Typography } from '@material-ui/core';

import LogoSrc from '../../assets/logo_dost.png';

export function Footer() {
  return (
    <FooterWrapper>
      <FooterLinks>
        <FooterLink>
          <Link component={RouterLink} to="/">
            Home
          </Link>
        </FooterLink>
        <FooterLink>
          <Link component={RouterLink} to="/about">
            About
          </Link>
        </FooterLink>
        <FooterLink>
          <Link component={RouterLink} to="/privacy-policy">
            Privacy Policy
          </Link>
        </FooterLink>
        <FooterLink>
          <Link component={RouterLink} to="/faq">
            FAQ
          </Link>
        </FooterLink>
      </FooterLinks>
      <Copyright>
        <FooterCaption variant="caption">Developed by</FooterCaption>
        <img src={LogoSrc} alt="DOST Logo" width="40" />
      </Copyright>
    </FooterWrapper>
  );
}

const Copyright = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const FooterWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const FooterCaption = styled(Typography)({
  marginRight: '10px',
});

const FooterLinks = styled('ul')({
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0',
});

const FooterLink = styled('li')(({ theme }) => ({
  marginRight: '1rem',

  '&:last-child': {
    marginRight: '0',
  },
  '& a': {
    color: theme.palette.common.white,
  },
  '& a:hover': {
    textDecoration: 'none !important',
  },
}));

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserDeclaration } from './UserDeclaration';
import { NotFound } from '../NotFound';
import { Checkpoint } from './Checkpoint';
import { Individuals } from './Individuals';
import { Establishments } from './Establishments';

export function Register() {
  return (
    <Switch>
      <Route
        exact
        path="/register/establishments"
        render={() => <Establishments />}
      />
      <Route
        exact
        path="/register/individuals"
        render={() => <Individuals />}
      />
      <Route exact path="/register/checkpoint" render={() => <Checkpoint />} />
      <Route exact path="/register" render={() => <UserDeclaration />} />
      <Route render={() => <NotFound />} />
    </Switch>
  );
}

import React from 'react';

import { Box, styled } from '@material-ui/core';

import { Footer } from '../components/common/Footer';
import { Navbar } from '../components/common/Navbar';
import { Props } from '../shared/Props';

export function DefaultLayout({ children }: Props) {
  return (
    <>
      <Navbar />

      <Content component="main">{children}</Content>

      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  );
}

const Content = styled(Box)({
  paddingTop: '64px',
  backgroundColor: '#fff',
});

const FooterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '150px',
}));

import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { AppRoutes } from './AppRoutes';
import { theme } from './styles/theme';

const history = createBrowserHistory();

ReactGA.initialize('UA-128977437-6');
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

export function App() {
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          maxSnack={1}
        >
          <RecoilRoot>
            <CssBaseline />

            <AppRoutes />
          </RecoilRoot>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

import { styled } from '@material-ui/core';
import { CancelRounded, CheckCircleOutlineRounded } from '@material-ui/icons';

export const SuccessIcon = styled(CheckCircleOutlineRounded)(({ theme }) => ({
  color: `${theme.palette.success.main} !important`,
}));

export const ErrorIcon = styled(CancelRounded)(({ theme }) => ({
  color: `${theme.palette.error.main} !important`,
}));

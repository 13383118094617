import { Card, styled } from '@material-ui/core';

export const FormWrapper = styled(Card)(({ theme }) => ({
  width: '568px',
  paddingTop: '1rem',
  paddingBottom: '2rem',
  margin: '0 auto',
  ...theme.mixins.gutters(),

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

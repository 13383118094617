import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  AppBar,
  Hidden,
  Slide,
  styled,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';

import LogoSrc from '../../assets/logo_transparent.svg';
import { RegisterButton } from '../register/RegisterButton';

function HideOnScroll({ children, window }: any) {
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export function Navbar(props: any) {
  const history = useHistory();

  function handleHome() {
    history.push('/');
  }

  return (
    <HideOnScroll {...props}>
      <AppBar>
        <Toolbar>
          <LogoWrapper>
            <img
              src={LogoSrc}
              onClick={handleHome}
              alt="FastPass Logo"
              width="40"
              height="40"
            />
            <Hidden only="xs">
              <Typography variant="body1">FastPass</Typography>
            </Hidden>
          </LogoWrapper>

          <RegisterButton size="medium" />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

const LogoWrapper = styled('section')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  '& img': {
    marginRight: '5px',
  },
  '& img:hover': {
    cursor: 'pointer',
  },
});

import { styled, Typography } from '@material-ui/core';

export const RegisterCaption = styled(Typography)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',

  '& a': {
    color: theme.palette.common.black,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

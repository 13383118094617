import { FormHelperText, styled } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormItem } from './Form/FormItem';
import { FormSectionHeader } from './Form/FormSectionHeader';
import { PhotoOutlined } from '@material-ui/icons';

interface Props {
  label: string;
  name: string;
  error: any;
  helperText: any;
}

export function ImageUploader({ name, label, error, helperText }: Props) {
  const [preview, setPreview] = useState<any>(null);
  const { setFieldTouched, setFieldValue, setErrors } = useFormikContext<any>();
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxFiles: 1,
    maxSize: 5242880,
    minSize: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFieldTouched(name, false);
        setFieldValue(name, acceptedFiles[0]);
        setPreview(URL.createObjectURL(acceptedFiles[0]));
      }
    },
    onDropRejected: (errors) => {
      if (errors.length > 0) {
        setErrors({ [name]: errors[0].errors[0].message });
      }
    },
  });

  return (
    <FormItem>
      <FormSectionHeader variant="caption">{label}</FormSectionHeader>
      <Wrapper {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {preview !== null ? (
          <Preview>
            <img src={preview} />
          </Preview>
        ) : (
          <>
            <PhotoOutlined />
            <p>Drop or click to upload a photo</p>
          </>
        )}
      </Wrapper>
      <FormHelperText error={error}>
        {error ? helperText : 'Maximum File Size: 5mb'}
      </FormHelperText>
    </FormItem>
  );
}

const Wrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px',
  border: '2px Dashed #eee',
  color: '#bdbdbd',
  marginTop: '0.75rem',

  '&:focus, &:hover': {
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const Preview = styled('article')({
  width: '200px',
  height: '100%',

  '& img': {
    height: '100%',
    width: '100%',
  },
});

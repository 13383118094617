import { Button, styled } from '@material-ui/core';

export const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  borderRadius: '2rem',

  '&:hover': {
    backgroundColor: theme.palette.success.light,
  },
}));

export const CancelButton = styled(Button)({
  borderRadius: '2rem',
});

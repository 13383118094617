import { styled } from '@material-ui/core';

export const OptionsItem = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: `1px Solid ${theme.palette.grey[300]}`,
  padding: '0.5rem 0',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },

  '& span': {
    fontSize: '0.75rem',
  },
}));

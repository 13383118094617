import React from 'react';

import { Box, styled } from '@material-ui/core';

import { Props } from '../../shared/Props';

interface ContentProps extends Props {
  align: 'center' | 'left' | 'right' | 'justify';
  width: number;
}

export function Content({ children, ...props }: ContentProps) {
  return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled(Box)(({ theme, align, width }: any) => ({
  ...theme.mixins.gutters(),
  textAlign: align,
  width: '100%',
  maxWidth: width,
  padding: '1.5rem 0',
  margin: '0 auto',
  wordWrap: 'break-word',

  [theme.breakpoints.down('sm')]: {
    '& p': {
      fontSize: '0.75rem',
    },
  },
}));

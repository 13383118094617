import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { recoverPassword, RecoverPassword } from '../../api/recover';
import { RecoverSuccessMessage } from './RecoverSuccessMessage';
import { FormItem } from '../common/Form/FormItem';
import { CircularProgress, TextField } from '@material-ui/core';
import { FormActions } from '../common/Form/FormActions';
import { useSnackbar } from 'notistack';
import { SubmitButton } from '../common/Buttons';

export function RecoverPasswordForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  async function handleSubmit(
    values: RecoverPassword,
    { setErrors }: FormikHelpers<RecoverPassword>,
  ) {
    setIsSubmitting(true);

    try {
      await recoverPassword(values);
      setIsSuccess(true);
    } catch (error) {
      const errors = error.response.data.errors;

      if (typeof errors !== 'undefined') {
        const parsedErrors = errors.reduce((outputObject: any, item: any) => {
          outputObject[item.param] = item.msg;

          return outputObject;
        }, {});

        setErrors(parsedErrors);
        return;
      }

      enqueueSnackbar('Internal Server Error');
    } finally {
      setIsSubmitting(false);
    }
  }

  function initializeValues(): RecoverPassword {
    const initialValues: RecoverPassword = {
      email: '',
    };

    return Object.assign({}, initialValues);
  }

  function initializeValidation(): yup.ObjectSchema {
    return yup.object<RecoverPassword>({
      email: yup
        .string()
        .email('You have entered an invalid email address.')
        .required('Field is required'),
    });
  }

  return isSuccess ? (
    <RecoverSuccessMessage />
  ) : (
    <Formik
      initialValues={initializeValues()}
      validationSchema={initializeValidation()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormItem>
            <TextField
              name="email"
              variant="outlined"
              size="small"
              label="Email Address *"
              margin="dense"
              error={typeof errors.email !== 'undefined' && touched.email}
              helperText={
                typeof errors.email !== 'undefined' && touched.email
                  ? errors.email
                  : ''
              }
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              fullWidth
            />
          </FormItem>

          <FormActions>
            {isSubmitting ? (
              <CircularProgress size={25} />
            ) : (
              <SubmitButton type="submit" variant="contained" color="primary">
                Submit
              </SubmitButton>
            )}
          </FormActions>
        </form>
      )}
    </Formik>
  );
}

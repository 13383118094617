import React from 'react';
import { Helmet } from 'react-helmet';

import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { ContentDisplay } from '../components/content/ContentDisplay';
import { RegisterButton } from '../components/register/RegisterButton';
import AboutPath from '../contents/About.md';
import { CenteredLayout } from '../layouts/CenteredLayout';

export function Home() {
  return (
    <CenteredLayout>
      <Helmet>
        <title>FastPass</title>
      </Helmet>

      <Descrption>
        <ContentDisplay path={AboutPath} />
      </Descrption>

      <CallToAction>
        <RegisterButton size="large" />
      </CallToAction>
    </CenteredLayout>
  );
}

const Descrption = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  maxWidth: '468px',
  width: '100%',
  textAlign: 'center',
  margin: '0 auto',
}));

const CallToAction = styled(Box)({
  marginTop: '3rem',
  textAlign: 'center',
});

import { Fade } from '@material-ui/core';
import { ArrowBackIosOutlined } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { registrationIndividualType } from '../../atoms/RegistrationIndividualType';
import { FormWrapper } from '../../components/common/Form/FormWrapper';
import { HeaderForm } from '../../components/common/Header/HeaderForm';
import { RegisterAction } from '../../components/register/RegisterAction';
import { RegisterCaption } from '../../components/register/RegisterCaption';
import { RegisterFormIndividual } from '../../components/register/RegisterForm/RegisterFormIndividual';
import { RegisterIndividualTypeOptions } from '../../components/register/RegisterIndividualTypeOptions';
import { CenteredLayout } from '../../layouts/CenteredLayout';

export function Individuals() {
  const history = useHistory();
  const [individualType, setIndividualType] = useRecoilState(
    registrationIndividualType,
  );

  function handleCancel() {
    history.push('/register/checkpoint');
  }

  useEffect(() => {
    return () => setIndividualType(null);
  }, []);

  return (
    <CenteredLayout>
      <Fade in={true}>
        <FormWrapper>
          {individualType !== null ? (
            <>
              <HeaderForm title={`Register as ${individualType}`} />
              <RegisterFormIndividual />
            </>
          ) : (
            <>
              <HeaderForm title="I am registering as.." />
              <RegisterIndividualTypeOptions />

              <RegisterCaption variant="caption">
                Choose <strong>Employee</strong> if affliated to a Government
                Agency/Entity.
              </RegisterCaption>

              <RegisterAction variant="text" onClick={handleCancel}>
                <ArrowBackIosOutlined />
                <span>Go Back</span>
              </RegisterAction>
            </>
          )}
        </FormWrapper>
      </Fade>
    </CenteredLayout>
  );
}

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Header } from '../components/common/Header';
import { Content } from '../components/content';
import { ContentDisplay } from '../components/content/ContentDisplay';
import PrivacyPolicyContentPath from '../contents/PrivacyPolicy.md';
import { DefaultLayout } from '../layouts/DefaultLayout';

export function PrivacyPolicy() {
  const [updated] = useState('December 01, 2020');

  return (
    <DefaultLayout>
      <Helmet>
        <title>FastPass Privacy Policy</title>
      </Helmet>

      <Header
        title="FastPass Privacy Policy"
        description={`Last Updated: ${updated}`}
      />

      <Content align="justify" width={768}>
        <ContentDisplay path={PrivacyPolicyContentPath} />
      </Content>
    </DefaultLayout>
  );
}

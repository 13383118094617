import React from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@material-ui/core';

import { Message } from '../common/Message';
import { RegisterCaption } from '../register/RegisterCaption';
import { SuccessIcon } from '../common/Icons';

export function ResetSuccessMessage() {
  return (
    <Wrapper>
      <SuccessIcon />

      <Message variant="body1">
        You have successfully changed your password, you can now use your new
        password.
      </Message>

      <RegisterCaption variant="caption">
        Having problems? Please check our <Link to="/faq">FAQs</Link> for more
        information.
      </RegisterCaption>
    </Wrapper>
  );
}

const Wrapper = styled('section')(({ theme }) => ({
  textAlign: 'center',

  '& svg': {
    fontSize: '5rem',
    color: theme.palette.primary.main,
  },
}));

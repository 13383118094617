import React from 'react';

import { Typography } from '@material-ui/core';
import { BusinessOutlined, PersonOutlined } from '@material-ui/icons';

import { Options } from '../common/Options';
import { OptionsItem } from '../common/Options/OptionsItem';
import { useHistory } from 'react-router-dom';

export function RegisterTypeOptions() {
  const history = useHistory();

  function handleIndividual() {
    history.push('/register/individuals');
  }

  function handleEstablishment() {
    history.push('/register/establishments');
  }

  return (
    <Options>
      <OptionsItem onClick={handleIndividual}>
        <PersonOutlined />
        <Typography variant="caption">Individual</Typography>
      </OptionsItem>
      <OptionsItem onClick={handleEstablishment}>
        <BusinessOutlined />
        <Typography variant="caption">Establishment</Typography>
      </OptionsItem>
    </Options>
  );
}

import { Fade } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormWrapper } from '../../components/common/Form/FormWrapper';
import { HeaderForm } from '../../components/common/Header/HeaderForm';
import { RegisterCaption } from '../../components/register/RegisterCaption';
import { RegisterTypeOptions } from '../../components/register/RegisterTypeOptions';
import { CenteredLayout } from '../../layouts/CenteredLayout';

export function Checkpoint() {
  return (
    <CenteredLayout>
      <Fade in={true}>
        <FormWrapper>
          <HeaderForm title="I want to register as.." />
          <RegisterTypeOptions />

          <RegisterCaption variant="caption">
            Please visit our <Link to="/faq">FAQs</Link> for more information.
          </RegisterCaption>
        </FormWrapper>
      </Fade>
    </CenteredLayout>
  );
}
